import React, { Component } from "react";
import { Waypoint } from "react-waypoint";

class Contact extends Component {
  render() {
    return (
      <div className="contact" id="js-contact">
        <Waypoint
          onEnter={function() {
            const container = document.getElementById("js-contact");
            container.classList.add("contact--active");
          }}
        />
        <h2>Contact me</h2>
        <div className="underline"></div>
        <div className="contact__inner">
          <a
                href="https://github.com/AvanDriel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="icon icon-github"
                  viewBox="0 0 99 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                  <path
                    d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                    stroke="#F22757"
                    strokeWidth="5"
                  />
                  <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                  <path
                    d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                    stroke="black"
                    strokeOpacity="0.45"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                    stroke="#ED5499"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                    stroke="#FFC0D7"
                    strokeWidth="5"
                  />
                  <path
                    d="M39.6718 72.9687C39.6718 76.0502 39.7173 78.9478 39.7173 79.8217V82L57.8138 79.8217C57.8138 78.5799 57.8592 74.6244 57.8592 69.7031C57.8592 66.2536 56.7225 64.0459 55.4039 62.8961C63.4974 61.9762 72 58.8487 72 44.7287C72 40.6812 70.5905 37.4157 68.2716 34.8401C68.6353 33.9202 69.9084 30.1487 67.9078 25.0894C67.9078 25.0894 64.8614 24.0776 57.9047 28.8609C54.9947 28.033 51.9029 27.6191 48.811 27.6191C45.7191 27.6191 42.6272 28.033 39.7173 28.8609C32.7605 24.1236 29.7141 25.0894 29.7141 25.0894C27.7135 30.1487 28.9866 33.9202 29.3504 34.8401C27.0315 37.4157 25.622 40.7272 25.622 44.7287C25.622 58.8027 34.0791 61.9762 42.1726 62.8961C41.1268 63.8159 40.1719 65.4257 39.8537 67.8174C37.7621 68.7832 32.5332 70.347 29.2595 64.7818C28.5774 63.678 26.5313 60.9644 23.6668 61.0103C20.6204 61.0563 22.4392 62.7581 23.7123 63.448C25.2582 64.3219 27.0315 67.5874 27.4407 68.6453C28.1682 70.715 30.5326 74.6704 39.6718 72.9687Z"
                    fill="#260065"
                  />
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/in/alwinvandriel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="icon icon-linkedin"
                  viewBox="0 0 99 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                  <path
                    d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                    stroke="#F22757"
                    strokeWidth="5"
                  />
                  <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                  <path
                    d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                    stroke="black"
                    strokeOpacity="0.45"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                    stroke="#ED5499"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                    stroke="#FFC0D7"
                    strokeWidth="5"
                  />
                  <path d="M30 40L20 41V84L30 83V40Z" fill="#260065" />
                  <path d="M49 38L39 39V82L49 81V38Z" fill="#260065" />
                  <path d="M75 35L65 36V79L75 78V35Z" fill="#260065" />
                  <path d="M65 62L49 38V55L65 79V62Z" fill="#260065" />
                  <path d="M30 28L20 29V37L30 36V28Z" fill="#260065" />
                </svg>
              </a>

              <a
                href="mailto:avandriel1998@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="icon icon-email"
                  viewBox="0 0 99 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                  <path
                    d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                    stroke="#F22757"
                    strokeWidth="5"
                  />
                  <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                  <path
                    d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                    stroke="black"
                    strokeOpacity="0.45"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                    stroke="#ED5499"
                    strokeWidth="4.8"
                  />
                  <path
                    d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                    stroke="#FFC0D7"
                    strokeWidth="5"
                  />
                  <path
                    d="M75 70.5L20 76.5V49.5L45.5 31L75 42.5V70.5Z"
                    fill="#260065"
                  />
                  <path d="M75 42.5L20 49.5L45.5 58.5L75 42.5Z" fill="#11C3CC" />
                </svg>
              </a>
          </div>
      </div>
    );
  }
}

export default Contact;
