import React, { Component } from "react";

class Splash extends Component {
  render() {
    return (
      <div className="splash">
        <div className="splash__animation">
          <svg
            className="letter letter-a"
            viewBox="0 0 100 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="box-purple"
              d="M41.5 14L14 160.5L47 157.5L51 138L63 137L67 155L100 152L72 11L41.5 14Z"
              fill="#8D05C6"
            />
            <path
              id="box-red"
              d="M33.5 7L6 153.5L39 150.5L43 131L55 130L59 148L92 145L64 4L33.5 7Z"
              fill="#F22757"
            />
            <path
              id="box-blue"
              d="M33.5 9L7.5 148.5L33 146.5L37 127L49 126L53 144L86 141L59 6.5L33.5 9Z"
              fill="#11C3CC"
            />
            <path
              id="box-shadow"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 148.5L34.5 7L58.5 4.5L86 141L53 144L49 126L37 127L33 146.5L8 148.5ZM55.5 11L38.5 12.5L14 143.5L29 142L32 126.5L56.92 121.65L59 122.2L63 138.5L80 136.5L55.5 11Z"
              fill="#0FAAB1"
            />
            <path
              id="box-shadow"
              d="M37.5 108L59.5 106L48.5 51.7L46 51L35 107L37.5 108Z"
              fill="#0FAAB1"
            />
            <path
              id="box-innerpink"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.5 149L31.5 4L58 1.5L86 141L56 143L52 125.5L37 126.5L33 146.5L4.5 149ZM54.5 7.5L35.5 9.5L10.5 143.5L29 142L32.85 123.5L52.92 120.65L56.93 121.65L60.92 139.62L80 136.5L54.5 7.5Z"
              fill="#ED5499"
            />
            <path
              id="box-innerpink"
              d="M35 107L57.5 104.7L46 51L42 50L32.5 105.8L35 107Z"
              fill="#ED5499"
            />
            <path
              id="box-pink"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 149.5L27.5 3L58 0L86 141L53 144L49 126L37 127L33 146.5L0 149.5ZM54 5.4L31.7 7.6L6.13 143.92L28.85 141.85L32.85 122.32L52.93 120.65L56.92 138.62L80 136.5L54 5.4Z"
              fill="#FFC0D7"
            />
            <path
              id="box-pink"
              d="M32.5 105.8L53.5 103.7L42 50L32.5 105.8Z"
              fill="#FFC0D7"
            />
          </svg>

          <svg
            className="letter letter-l"
            viewBox="0 0 81 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="box-purple"
              d="M12 14V157.5L81 151V120L44.5 123.5L43.5 11L12 14Z"
              fill="#8D05C6"
            />
            <path
              id="box-red"
              d="M4 7V150.5L73 144V113L36.5 116.5L35.5 4L4 7Z"
              fill="#F22757"
            />
            <path
              id="box-blue"
              d="M4 7.00002V146L69 140V113.5L32 116.5L31 4.5L4 7.00002Z"
              fill="#11C3CC"
            />
            <path
              id="box-shadow"
              d="M10.4 13.1338L28.135 11.686L29.1002 113.032L29.1173 114.831L30.614 115.829L33.6 117.82V119V122.734L37.3174 122.385L65.6 119.734V136.904L10.4 142.074V13.1338Z"
              stroke="black"
              strokeOpacity="0.13"
              strokeWidth="6.8"
            />
            <path
              id="box-innerpink"
              d="M7.4 10.0493L28.1334 7.78744L28.6001 60.5301L28.6002 60.5324L29.1002 113.032L29.1322 116.4H32.1V116.5V120.218L35.8033 119.886L65.6 117.218V136.899L7.4 142.272V10.0493Z"
              stroke="#ED5499"
              strokeWidth="6.8"
            />
            <path
              id="box-pink"
              d="M3.5 6.1825L28.034 3.84593L29.0001 112.531L29.0341 116.348L32.8341 115.984L65.5 112.852V136.814L3.5 142.655V6.1825Z"
              stroke="#FFC0D7"
              strokeWidth="7"
            />
          </svg>

          <svg
            className="letter letter-w"
            viewBox="0 0 150 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="box-purple"
              d="M12 24.5L39.5 165L70.5 162.5L81 107.5L91.5 160L122 157.5L149.5 11L116.5 14L107 65.5L96.5 16L65 19L55 70.5L45 21.5L12 24.5Z"
              fill="#8D05C6"
            />
            <path
              id="box-red"
              d="M5 17.5L32.5 158L63.5 155.5L74 100.5L84.5 153L115 150.5L142.5 4L109.5 7L100 58.5L89.5 9L58 12L48 63.5L38 14.5L5 17.5Z"
              fill="#F22757"
            />
            <path
              id="box-blue"
              d="M11 23.5L36.5 153.2L58.5 151.5L69 96.5L79.5 149L110 146.5L135.4 11L106 12.5L95.5 65L84 14.5L55 17.5L45 69L35 21.5L11 23.5Z"
              fill="#11C3CC"
            />
            <path
              id="box-shadow"
              d="M113.533 11.2064L133.251 9.21074L107.978 144.257L87.421 145.935L78.3504 102.015L78.1845 101.211L77.5619 100.678L70.5619 94.6778L67.4188 91.9837L66.6426 96.0499L56.4861 149.251L34.948 150.94L9.86154 21.648L32.3796 19.5859L40.6488 59.9813L40.8137 60.787L41.4381 61.3222L48.4381 67.3222L51.5698 70.0065L52.356 65.9575L62.0145 16.2164L83.9485 14.0951L92.6526 54.9995L92.8216 55.7938L93.4381 56.3222L100.438 62.3222L103.604 65.0357L104.36 60.9354L113.533 11.2064Z"
              stroke="#0EA6AD"
              strokeWidth="4.8"
            />
            <path
              id="box-shadow"
              d="M80.7 101.55L71.08 101.6L71 71L74 71.5L80.7 101.55Z"
              fill="#0EA6AD"
            />
            <path
              id="box-shadow"
              d="M54.7 66.45L45.1001 66.5L47 88.5L50 90.5L54.7 66.45Z"
              fill="#0EA6AD"
            />
            <path
              id="box-shadow"
              d="M106.72 61.4001L97.1101 61.53L99 84L102 86.5L106.72 61.4001Z"
              fill="#0EA6AD"
            />
            <path
              id="box-innerpink"
              d="M32.5482 151.139L6.8646 18.6658L31.5804 16.5713L40.6496 60.4854L40.8573 61.491L41.728 62.0352L45.728 64.5352L48.6902 66.3865L49.356 62.9575L59.0118 13.2299L83.1046 11.0788L92.6548 55.0098L92.8447 55.8835L93.56 56.42L97.56 59.42L100.658 61.7433L101.36 57.9354L110.529 8.2301L133.858 6.16924L107.982 144.24L84.4187 145.954L73.849 95.5078L73.4493 93.6H71.5H69H67.0115L66.6418 95.5539L56.4828 149.252L32.5482 151.139Z"
              stroke="#ED5499"
              strokeWidth="4.8"
            />
            <path
              id="box-innerpink"
              d="M76.43 96.05L66.6 96.1L68.5 70.5L71.02 71L76.43 96.05Z"
              fill="#ED5499"
            />
            <path
              id="box-innerpink"
              d="M51.7 63.45L42.1001 63.5L43 85.5L47 88.5L51.7 63.45Z"
              fill="#ED5499"
            />
            <path
              id="box-innerpink"
              d="M103.62 58.9L93.9 59.0299L95 80L99 84L103.62 58.9Z"
              fill="#ED5499"
            />
            <path
              id="box-pink"
              d="M106.615 5.31808L134.433 2.78915L107.895 144.164L81.5148 146.326L71.4515 96.0097L66.5443 96.0312L56.4014 149.161L29.5246 151.329L2.98565 15.7389L30.9979 13.1923L40.5505 59.9999L45.4542 59.9765L55.0979 10.3115L82.5171 7.70016L92.5544 55.0188L97.4585 54.9535L106.615 5.31808Z"
              stroke="#FFC0D7"
              strokeWidth="5"
            />
            <path
              id="box-pink"
              d="M73.91 95.55L64.08 95.6L68.5 70.5L73.91 95.55Z"
              fill="#FFC0D7"
            />
            <path
              id="box-pink"
              d="M47.8877 60.45L38.1001 60.5L43 85.5L47.8877 60.45Z"
              fill="#FFC0D7"
            />
            <path
              id="box-pink"
              d="M99.91 55.4001L90.1101 55.53L95 80L99.91 55.4001Z"
              fill="#FFC0D7"
            />
          </svg>

          <svg
            className="letter letter-i"
            viewBox="0 0 40 156"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="box-purple"
              d="M8 12V156L39.5 152.5V9L8 12Z"
              fill="#8D05C6"
            />
            <path id="box-red" d="M3 7V151L34.5 147.5V4L3 7Z" fill="#F22757" />
            <path
              id="box-blue"
              d="M10 11V145.804L31.5 143.5V8.92363L10 11Z"
              fill="#11C3CC"
            />
            <path
              id="box-shadow"
              d="M9 10.7203L28.5 8.8085V140.811L9 142.95V10.7203Z"
              stroke="#0EA6AD"
              strokeWidth="6"
            />
            <path
              id="box-innerpink"
              d="M5.9 8.64049L28.6 6.50924V140.902L5.9 143.399V8.64049Z"
              stroke="#ED5499"
              strokeWidth="5.8"
            />
            <path
              id="box-pink"
              d="M3 5.72786L28.5 3.29929V140.815L3 143.648V5.72786Z"
              stroke="#FFC0D7"
              strokeWidth="6"
            />
          </svg>

          <svg
            className="letter letter-n"
            viewBox="0 0 89 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="box-purple"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 161V17.5L40 14.5L57 61V13L88.5 10V153.5L59 156.5L42 110V158L10 161ZM16.5 23.1885V154.5L35.5 152.5V76L63.5 150L82.5 148V17L63.5 18.5L63 94L35.5 21.5L16.5 23.1885Z"
              fill="#8D05C6"
            />
            <path
              id="box-purple"
              d="M16.5 154.5V23.1885L35.5 21.5L63 94L63.5 18.5L82.5 17V148L63.5 150L35.5 76V152.5L16.5 154.5Z"
              fill="#8D05C6"
            />
            <path
              id="box-red"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 155V11.5L34 8.5L51 55V7L82.5 4V147.5L53 150.5L36 104V152L4 155Z"
              fill="#F22757"
            />
            <path
              id="box-blue"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 149.141V18.3599L34 16L51 62V12L78.5 10.5L78 142.5L49 145.5L32 99.3599V146.86L7 149.141Z"
              fill="#11C3CC"
            />
            <path
              id="box-shadow"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 149.26V13.5L32.4 11L47 51L54 57V8.99996L78.5 6.5V142.5L52.5 145L32 99.3201V147L7 149.26ZM13.5 19.1884V143.226L25.5 142V65.5703L31.5 70.06L58 138.5L72.5 137V13.5L60.5 14.5L60 88.7L53 83.42L28 18L13.5 19.1884Z"
              fill="#0EA6AD"
            />
            <path
              id="box-innerpink"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 149.612V10.4503L31 8L47 51L51 53.6623V5.98013L78.5 3.5V142.5L51.5 145L36 102.338L32 99V147L4 149.612ZM10.5 16.1011V146.543L29.5 144.556L25.5 65.56L29.5 68.5629L57.5 142.073L76.5 140.086V9.95364L57.5 11.4437L57 86.4437L53 83.43L29.5 14.4238L10.5 16.1011Z"
              fill="#ED5499"
            />
            <path
              id="box-pink"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 150V7.45033L30 4.4702L47 50.6623V2.98013L78.5 0V142.55L49 145.53L32 99.3378V147.02L0 150ZM6.5 13.1011V143.543L25.5 141.556V65.5629L53.5 139.073L72.5 137.086V6.95364L53.5 8.44371L53 83.4437L25.5 11.4238L6.5 13.1011Z"
              fill="#FFC0D7"
            />
          </svg>

          <div className="divider-line"></div>

          <div className="splash__icons">
            <a
              href="https://github.com/AvanDriel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="icon icon-github"
                viewBox="0 0 99 103"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                <path
                  d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                  stroke="#F22757"
                  strokeWidth="5"
                />
                <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                <path
                  d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                  stroke="black"
                  strokeOpacity="0.45"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                  stroke="#ED5499"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                  stroke="#FFC0D7"
                  strokeWidth="5"
                />
                <path
                  d="M39.6718 72.9687C39.6718 76.0502 39.7173 78.9478 39.7173 79.8217V82L57.8138 79.8217C57.8138 78.5799 57.8592 74.6244 57.8592 69.7031C57.8592 66.2536 56.7225 64.0459 55.4039 62.8961C63.4974 61.9762 72 58.8487 72 44.7287C72 40.6812 70.5905 37.4157 68.2716 34.8401C68.6353 33.9202 69.9084 30.1487 67.9078 25.0894C67.9078 25.0894 64.8614 24.0776 57.9047 28.8609C54.9947 28.033 51.9029 27.6191 48.811 27.6191C45.7191 27.6191 42.6272 28.033 39.7173 28.8609C32.7605 24.1236 29.7141 25.0894 29.7141 25.0894C27.7135 30.1487 28.9866 33.9202 29.3504 34.8401C27.0315 37.4157 25.622 40.7272 25.622 44.7287C25.622 58.8027 34.0791 61.9762 42.1726 62.8961C41.1268 63.8159 40.1719 65.4257 39.8537 67.8174C37.7621 68.7832 32.5332 70.347 29.2595 64.7818C28.5774 63.678 26.5313 60.9644 23.6668 61.0103C20.6204 61.0563 22.4392 62.7581 23.7123 63.448C25.2582 64.3219 27.0315 67.5874 27.4407 68.6453C28.1682 70.715 30.5326 74.6704 39.6718 72.9687Z"
                  fill="#260065"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/in/alwinvandriel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="icon icon-linkedin"
                viewBox="0 0 99 103"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                <path
                  d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                  stroke="#F22757"
                  strokeWidth="5"
                />
                <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                <path
                  d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                  stroke="black"
                  strokeOpacity="0.45"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                  stroke="#ED5499"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                  stroke="#FFC0D7"
                  strokeWidth="5"
                />
                <path d="M30 40L20 41V84L30 83V40Z" fill="#260065" />
                <path d="M49 38L39 39V82L49 81V38Z" fill="#260065" />
                <path d="M75 35L65 36V79L75 78V35Z" fill="#260065" />
                <path d="M65 62L49 38V55L65 79V62Z" fill="#260065" />
                <path d="M30 28L20 29V37L30 36V28Z" fill="#260065" />
              </svg>
            </a>

            <a
              href="mailto:avandriel1998@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="icon icon-email"
                viewBox="0 0 99 103"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 103V16.5314L99 6V93.0229L8 103Z" fill="#8D05C6" />
                <path
                  d="M91.5 86.782L5.5 96.2109V14.7588L91.5 4.80601V86.782Z"
                  stroke="#F22757"
                  strokeWidth="5"
                />
                <path d="M0 95V11.2057L89 1V85.3314L0 95Z" fill="#11C3CC" />
                <path
                  d="M88.6 84.8505L7.4 93.8203V17.0201L88.6 7.69151V84.8505Z"
                  stroke="black"
                  strokeOpacity="0.45"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.6 84.8451L5.4 93.827V15.3435L88.6 5.69442V84.8451Z"
                  stroke="#ED5499"
                  strokeWidth="4.8"
                />
                <path
                  d="M88.5 84.782L2.5 94.2109V12.7588L88.5 2.80601V84.782Z"
                  stroke="#FFC0D7"
                  strokeWidth="5"
                />
                <path
                  d="M75 70.5L20 76.5V49.5L45.5 31L75 42.5V70.5Z"
                  fill="#260065"
                />
                <path d="M75 42.5L20 49.5L45.5 58.5L75 42.5Z" fill="#11C3CC" />
              </svg>
            </a>
          </div>

          <h2 className="splash__subtitle">Creative Developer</h2>
        </div>
      </div>
    );
  }
}

export default Splash;
