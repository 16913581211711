import React from 'react';
import Splash from './Splash';
import Projects from './Projects'
import Languages from './Languages';
import About from './About';
import Contact from './Contact';

function App() {
  return (
    <div className="App">
      <Splash></Splash>
      <About></About>
      <Projects></Projects>
      <Languages></Languages>
      <Contact></Contact>
    </div>
  );
}

export default App;
