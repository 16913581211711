import React, { useEffect } from "react";

const About = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.getElementById("js-about");
      container.classList.add("about--active");
    }, 2750);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="about" id="js-about">
      <h2>About me</h2>
      <div className="underline"></div>
      <p>
        Thank you for visiting my portfolio website! My name is Alwin, and I'm
        currently working as a front-end developer at DotControl - an agency
        based in Rotterdam. Before this, I've studied Creative Media and Game
        Technologies and have done an intership at Yummygum where I worked on my
        thesis about automated code testing. In my day to day work I'm mostly
        working on React / Nextjs applications, realising designs as next-level
        websites. My passion is on the visual side of a project and I love
        building animations, but I am just as able to tackle the more
        data-related aspects of an application using GraphQL. This site is built
        to show a couple of projects I've worked on, both personal projects and
        things I've built (or helped building) for work. If you have any
        questions, feel free to send me a message!
      </p>
    </div>
  );
};

export default About;
