import React, { Component } from "react";
import StruktonImage from "../assets/struktoncard.png";
import TalpaImage from "../assets/talpacard.png";
import BackplannerImage from "../assets/backplannercard.png";
import GarrixImage from "../assets/garrixcard.png";
import GLImage from "../assets/guidinglight.png";
import { Waypoint } from "react-waypoint";
import ReactModal from "react-modal";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.myRef = React.createRef();

    const struktonText = (
      <p>
        During my internship at DotControl, a digital agency in Rotterdam, I had
        the opportunity to work on the new website for Strukton. I worked on
        this project since it started and was one of the two front-end
        developers.
        <br></br>
        This project can be seen{" "}
        <a
          href="https://strukton.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
        <br></br>
        <br></br>
        For developing this project we got designs delivered from the design
        team, which we implemented. I was mainly responsible for setting up the
        base front-end framework and implementing most of the animation work.
        These animations are mostly seen on the homepage, where I built the
        landing animation, menu animation and the scroll animation for the logo
        in the background.
        <br></br>
        <br></br>
        The site was built in the Sitecore CMS, where we built rendering
        components to be displayed on the website. The styling was done with
        scss, the animation a combination of scss and javascript with the help
        of the GSAP library.
      </p>
    );

    const GarrixText = (
      <p>
        During my Graduation Internship at Yummygum I was working on my thesis,
        but preferred working together with the team on this project instead.
        This new website for the Dutch DJ Martin Garrix was built in NextJs, and
        created with the atomic design methodology. By structuring our
        components this way and documenting everything in Storybook, we had a
        clear overview of everything that has been build for the duration of the
        project. I helped building the component library, and later on focussed
        on putting together some of the animations - such as the menu and
        loading animations.
        <br></br>
        <br></br>
        This website was built using NextJs and its serverside rendering
        capabilities, together with Prismic for managing content.
        <br></br>
        The project can be found{" "}
        <a
          href="https://martingarrix.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
      </p>
    );

    const TalpaText = (
      <p>
        During my internship at Dotcontrol, I got to work on the vacancy site
        for Talpa Media. This was built using the Umbraco CMS and I was mainly
        responsible for the overview page with the filtering option and the
        other blocks on that page. The project can be found{" "}
        <a
          href="https://www.werkenbijtalpamedia.tv/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
      </p>
    );

    const GuidingLightText = (
      <p>
        During the minor Game Design and Development, we worked in a team of 18
        students with the goal of building a 3D game in less than 16 weeks and
        releasing it on Steam.
        <br></br>
        <br></br>During this Minor, I was mostly involved with the Unity
        Development Side of the project, but I also did some social media to get
        some attention for the Game.
        <br></br>
        <br></br>
        Guiding Light was released at the end of January 2020 and, at time of
        writing, has had 14.000+ Unique users. The game can be found{" "}
        <a
          href="https://store.steampowered.com/app/1203680/Guiding_Light/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
      </p>
    );

    ReactModal.setAppElement("#root");

    const textArrayConst = [
      struktonText,
      GarrixText,
      TalpaText,
      GuidingLightText,
    ];
    this.state = {
      title: "Strukton",
      text: textArrayConst[0],
      textArray: textArrayConst,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, title, count) {
    this.setState({ title: title, text: this.state.textArray[count] });
    this.handleOpenModal();
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className="projects" id="js-projects">
        <Waypoint
          onEnter={function () {
            const container = document.getElementById("js-projects");
            container.classList.add("projects--active");
          }}
        />
        <h2>Projects</h2>
        <div className="underline"></div>
        <div className="projects__cards">
          <div
            className="projectcard"
            onClick={(event) => this.handleClick(event, "Strukton", 0)}
          >
            <img src={StruktonImage} alt="" />
            <h3>Strukton</h3>
            <h4>Internship DotControl</h4>
          </div>
          <div
            className="projectcard"
            onClick={(event) => this.handleClick(event, "Garrix", 1)}
          >
            <img src={GarrixImage} alt="" />
            <h3>Martin Garrix</h3>
            <h4>Internship Yummygum</h4>
          </div>
          <div
            className="projectcard"
            onClick={(event) => this.handleClick(event, "Talpa", 2)}
          >
            <img src={TalpaImage} alt="" />
            <h3>Talpa</h3>
            <h4>Internship DotControl</h4>
          </div>
          <div
            className="projectcard"
            onClick={(event) => this.handleClick(event, "Guiding Light", 3)}
          >
            <img src={GLImage} alt="" />
            <h3>Guiding Light</h3>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="Project Detail Modal"
          className="Modal"
          closeTimeoutMS={200}
        >
          <div className="projects__description" ref={this.myRef}>
            <h2>About {this.state.title}</h2>
            <div className="underline"></div>
            {this.state.text}
          </div>
          <button onClick={this.handleCloseModal}>Close</button>
        </ReactModal>
      </div>
    );
  }
}

export default Projects;
